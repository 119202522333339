import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { setTitle } from '../../actions'
// import logo from '../../assets/static/logo.png'
import styled from 'styled-components'

const Dashboard = () => {
  useEffect(() => {
    const topbar = {
      title: 'Dashboard',
    }
    setTitle(topbar)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Main>
      
      </Main>
    </>
  )
}

const mapDispatchToProps = {
  setTitle,
}

export default connect(null, mapDispatchToProps)(Dashboard)

const Main = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

// const Img = styled.img`
//   height: 30vh;
// `