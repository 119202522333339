import React, { useState } from 'react'
import styled from 'styled-components'
import { HashLink } from 'react-router-hash-link'
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'
import { AiOutlinePhone } from 'react-icons/ai'
import logo from '../../assets/static/logo.png'

const Header = props => {
  const [togleBar, setTogle] = useState(false)
  const [active, setActive] = useState(1)

  const handleClick = id => {
    setActive(id)
    setTogle(false)
  }

  return (
    <HeaderS>
      <Menu togleBar={togleBar}>
        <Img src={logo} alt="" />
        <Ul togleBar={togleBar}>
          <Li active={active === 1 ? true : false}>
            <HashLink
              smooth
              onClick={() => handleClick(1)}
              to="/#home"
            >
              Inicio
            </HashLink>
          </Li>

          <Li active={active === 2 ? true : false}>
            <HashLink
              smooth
              onClick={() => handleClick(2)}
              to="/#about"
            >
              Nosotros
            </HashLink>
          </Li>

          <Li active={active === 3 ? true : false}>
            <HashLink
              smooth
              onClick={() => handleClick(3)}
              to="/#services"
            >
              Servicios
            </HashLink>
          </Li>
          <Li active={active === 4 ? true : false}>
            <HashLink
              smooth
              onClick={() => handleClick(4)}
              to="/#programs"
            >
              Programas
            </HashLink>
          </Li>
          <Li active={active === 5 ? true : false}>
            <HashLink
              smooth
              onClick={() => handleClick(5)}
              to="/#offices"
            >
              Oficinas
            </HashLink>
          </Li>
          <Li active={active === 6 ? true : false}>
            <HashLink
              smooth
              onClick={() => handleClick(6)}
              to="/#news"
            >
              Noticias
            </HashLink>
          </Li>
        </Ul>

        <IconContainer href={`tel: +522222222222`} target="blank">
          <Icon>
            <AiOutlinePhone />
          </Icon>
          <IconText>
            <p>Contáctanos</p>
            <p>01 8000 000 000</p>
          </IconText>
        </IconContainer>

        <Hamburguer onClick={() => setTogle(!togleBar)}>
          {togleBar ? <AiOutlineClose /> : <AiOutlineMenu />}
        </Hamburguer>
      </Menu>
    </HeaderS>
  )
}

export default Header

const HeaderS = styled.header`
  position: absolute;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  height: 80px;
  width: 100vw;
  box-sizing: border-box;
  z-index: 99999;
  background: transparent;
`

const Menu = styled.div`
  grid-column: 2 / 3;
  z-index: 11;
  max-width: 100vw;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  max-width: 100vw;
  padding: 5px 0;
  background: transparent;
  width: 100%;
  position: relative;
`

const Ul = styled.ul`
  padding: 0;
  display: flex;
  align-items: center;
  list-style: none;
  transition: all 1s ease-out;
  @media (max-width: 768px) {
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    margin: 0;
    width: 100vw;
    height: 100vh;
    transition: all 0.5s ease-out;
    flex-direction: column;
    justify-content: center;
    boz-sizing: border-box;
    top: 0;
    left: 0;
    ${props =>
      props.togleBar === false
        ? 'transform: translateX(-100%);'
        : 'transform: translateX(0%);'}
  }
`

const Hamburguer = styled.div`
  font-family: 'Quicksand', sans-serif;
  font-weight: lighter;
  color: ${({ theme }) => theme.white};
  font-size: 1.5rem;
  font-weight: 100;
  margin-top: 10px;
  margin-right: 25px;
  padding-bottom: 10px;
  display: none;
  @media (max-width: 768px) {
    z-index: 12;
    display: block;
  }
`

const Li = styled.li`
  margin: 0 25px;
  font-size: 16px;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
  & a {
    font-family: 'Open Sans', sans-serif;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    text-align: center;
    border-bottom: ${props =>
      props.active ? '2px solid #fff' : 'none'};
    padding: 7px 0;

    @media (max-width: 768px) {
      font-size: 1rem;
      color: #fff;
    }
  }
`

const Img = styled.img`
  height: 60px;
  object-fit: contain;
  @media (max-width: 768px) {
    height: 35px;
    padding-left: 10px;
  }
`

const IconContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  @media (max-width: 768px) {
    display: none;
  }
`

const IconText = styled.div`
  & p {
    margin: 0;
    color: rgba(136, 136, 136, 1);
    font-weight: 200;
    &:first-child {
      font-weight: 600;
      color: #44648a;
      font-size: 25px;
    }
  }
`

const Icon = styled.div`
  border: 1px solid #42baed;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    color: #42baed;
    font-size: 35px;
  }
`
