import React from 'react'
import styled from 'styled-components'

const Title = ({ children, color, size }) => {
  return (
    <TitleStyled color={color} size={size}>
      {children}
    </TitleStyled>
  )
}

export default Title

const TitleStyled = styled.div`
  font-weight: 600;
  font-size: ${({ size }) =>
    size === 'big' ? '70px' : size === 'mid' ? '40px' : '18px'};
  margin-bottom: ${({ size }) =>
    size === 'big' ? '10px' : size === 'mid' ? '10px' : '10px'};
  letter-spacing: ${({ size }) =>
    size === 'big' ? '4px' : size === 'mid' ? '3px' : '2px'};
  color: ${props => {
    switch (props.color) {
      case 'secondary':
        return props.theme.secondary
      case 'white':
        return props.theme.white
      case 'info':
        return props.theme.info
      case 'success':
        return props.theme.success

      default:
        return props.theme.primary
    }
  }};
  line-height: ${({ size }) =>
    size === 'big' ? '' : size === 'mid' ? '1.5' : '1'};;
  position: relative;
  @media (max-width: 768px) {
    font-size: ${({ size }) =>
      size === 'big' ? '30px' : size === 'mid' ? '25px' : '15px'};
    margin-bottom: ${({ size }) =>
      size === 'big' ? '30px' : size === 'mid' ? '25px' : '15px'};
    letter-spacing: ${({ size }) =>
      size === 'big' ? '4px' : size === 'mid' ? '3px' : '1px'};
  }
`
