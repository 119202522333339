import React from 'react'
import styled from 'styled-components'
import logo from '../../assets/static/logo.png'
import { HashLink } from 'react-router-hash-link'

const Footer = props => {
  return (
    <Main>
      <Container>
        <Img src={logo} alt="logo" />
        <Box>
          <Left>
            <A to="/#home" smooth>
              Inicio
            </A>
            <A to="/#about" smooth>
              Nosotros
            </A>
            <A to="/#services" smooth>
              Servicios
            </A>
          </Left>
          <Left>
            <A to="/#programs" smooth>
              Programas
            </A>
            <A to="/#offices" smooth>
              Oficinas
            </A>
            <A to="/#news" smooth>
              Noticias
            </A>
          </Left>
        </Box>
        <Right>
          <a href="tel:+52222222222">+ 52 222 222 2222</a>
          <a href="mailto:contacto@casadelmigrante.com">
            contacto@casadelmigrante.com
          </a>
        </Right>
      </Container>
      <P>
        Todos los derechos reservados a LA CASA DEL MIGRANTE{' '}
        <br />
        Desarrollado por Zuse Technologies 2023.
      </P>
    </Main>
  )
}

export default Footer

const Main = styled.footer`
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  color: #fff;
  position: relative;
  background-color: ${({ theme }) => theme.secondary};
  padding-top: 50px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Container = styled.div`
  grid-column: 2/3;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    grid-column: 1;
    padding: 0 15px;
  }
`

const A = styled(HashLink)`
  display: flex;
  font-family: 'PT Sans', sans-serif;
  align-items: center;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  margin-bottom: 5px;
  &:hover {
    color: ${({ theme }) => theme.secondary};
  }
`

const P = styled.p`
  grid-column: 2/3;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  @media (max-width: 768px) {
    flex-direction: column;
    grid-column: 1;
    padding: 0 15px;
  }
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
  }
`

const Img = styled.img`
  height: 100px;
  object-fit: contain;

  @media (max-width: 768px) {
    height: 100px;
    margin-bottom: 25px;
  }
`

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    &:nth-child(1) {
      margin-right: 50px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 25px;
    > div {
      &:nth-child(1) {
        margin-right: 0;
      }
    }
  }
`

const Right = styled.div`
  display: flex;
  flex-direction: column;
  > a {
    text-decoration: none;
    color: #fff;
    margin-bottom: 5px;
    text-align: right;
    &:nth-child(1) {
      font-weight: 400;
      font-size: 2rem;
    }
  }
  @media (max-width: 768px) {
    margin-bottom: 25px;
    > a {
      text-align: center;
      &:nth-child(1) {
        font-size: 1.5rem;
      }
    }
  }
`
