export default function reducerCustomers(state = {}, action) {
  switch (action.type) {
    case 'GET_OFFICES':
      return {
        ...state,
        offices: action.payload.data,
      }

    case 'GET_OFFICE':
      return {
        ...state,
        office: action.payload,
      }
    default:
      return state
  }
}
