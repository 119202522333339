import React from 'react'
import styled from 'styled-components'
import Banner from './Banner'
import ProductsTop from './ProductsTop'
import Programs from './Programs'
import About from './About'
import Services from './Services'
import Customers from './Customers'
import Contact from './Contact'
import News from './News'
import Form from './Form'

const Home = () => {
  return (
    <Main id="home">
      <Banner />
      <News />
      <About />
      <ProductsTop />
      <Services />
      <Programs />
      <Customers />
      <Contact />
      <Form />
    </Main>
  )
}

export default Home

const Main = styled.main``
