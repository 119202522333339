import React from 'react'
import styled from 'styled-components'

const Vision = props => {
  return (
    <Main id="products">
      <Box>
        <BoxCard>
          <BoxText>
            97<Span>%</Span>
          </BoxText>
          <BoxText>Trámites Exitosos</BoxText>
        </BoxCard>
        <BoxCard>
          <BoxText>
            9.6<Span>K</Span>
          </BoxText>
          <BoxText>Clientes Satisfechos</BoxText>
        </BoxCard>
        <BoxCard>
          <BoxText>
            38<Span>+</Span>
          </BoxText>
          <BoxText>Abogados expertos</BoxText>
        </BoxCard>
        <BoxCard>
          <BoxText>
            20<Span>+</Span>
          </BoxText>
          <BoxText>Años de experiencia</BoxText>
        </BoxCard>
      </Box>
    </Main>
  )
}

export default Vision

const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  z-index: 1;
  position: relative;
  background-color: #fff;
  padding-bottom: 50px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Box = styled.div`
  grid-column: 2/3;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 30px 0;

  color: ${({ theme }) => theme.secondary};

  @media (max-width: 768px) {
    grid-column: 1/2;
    flex-direction: column;
  }
`

const BoxCard = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid rgba(217, 217, 217, 1);
  &:nth-child(1) {
    border-left: none;
  }
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (max-width: 768px) {
    margin-bottom: 50px;
    border: none;
  }
`

const BoxText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 70px;
  font-weight: 600;
  text-align: center;

  &:nth-child(2) {
    color: rgba(136, 136, 136, 1);
    font-size: 20px;
    font-weight: 500;
  }
`

const Span = styled.span`
  color: rgba(66, 186, 237, 1);
  font-size: 40px;
  margin-left: 25px;
  font-weight: 500;
`
