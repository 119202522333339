import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getAll } from '../../actions'
import styled from 'styled-components'
import Title from '../../components/Title'
import { CgEditBlackPoint } from 'react-icons/cg'

const Vision = props => {
  const { news = [] } = props

  useEffect(() => {
    props.getAll('news', 'GET_NEWS')
    //eslint-disable-next-line
  }, [])

  return (
    <Main id="services">
      <Container>
        <div>
          <Top>
            <CgEditBlackPoint />
            Noticias
          </Top>
        </div>
        <Title size="mid" color="secondary">
          Te mantenemos al tanto
        </Title>
        <Subtitle>
          Entérate de las últimas noticias y conoce más sobre nuestro
          trabajo
        </Subtitle>
        {news.length > 0 ? (
          <Box>
            <div>
              {news.slice(1, 4).map(item => (
                <Card key={item.id}>
                  <div>
                    <Title size="small" color="secondary">
                      {item.title}
                    </Title>
                    <Text>{item.text.slice(0, 200)}...</Text>
                    <Link href={item.link} target="blank">
                      Leer más
                    </Link>
                  </div>
                  <Img
                    src={`${process.env.REACT_APP_API}public/news/${item.image}`}
                    alt={'imagen'}
                  />
                </Card>
              ))}
            </div>
            <CardMain>
              <div>
                <Title size="small" color="secondary">
                  {news[0].title}
                </Title>
                <Text>{news[0].text.slice(0, 200)}...</Text>
                <Link href={news[0].link} target="blank">
                  Leer más
                </Link>
              </div>
              <Img
                src={`${process.env.REACT_APP_API}public/news/${news[0].image}`}
                alt={'imagen'}
              />
            </CardMain>
          </Box>
        ) : (
          <Box>
            <div>
              <Card>
                <div>
                  <Title size="small" color="secondary">
                    Lorem ipsum dolor sit, amet consectetur
                    adipisicing elit. Rerum, voluptatibus
                  </Title>
                  <Text>
                    Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit, sed do eiusmod tempor incididunt ut labore
                    et dolore
                  </Text>
                  <Link href="https://google.com.mx" target="blank">
                    Leer más
                  </Link>
                </div>
                <Img src="https://via.placeholder.com/150x150" />
              </Card>
              <Card>
                <div>
                  <Title size="small" color="secondary">
                    Lorem ipsum dolor sit, amet consectetur
                    adipisicing elit. Rerum, voluptatibus
                  </Title>
                  <Text>
                    Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit, sed do eiusmod tempor incididunt ut labore
                    et dolore
                  </Text>
                  <Link href="https://google.com.mx" target="blank">
                    Leer más
                  </Link>
                </div>
                <Img src="https://via.placeholder.com/150x150" />
              </Card>
              <Card>
                <div>
                  <Title size="small" color="secondary">
                    Lorem ipsum dolor sit, amet consectetur
                    adipisicing elit. Rerum, voluptatibus
                  </Title>
                  <Text>
                    Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit, sed do eiusmod tempor incididunt ut labore
                    et dolore
                  </Text>
                  <Link href="https://google.com.mx" target="blank">
                    Leer más
                  </Link>
                </div>
                <Img src="https://via.placeholder.com/150x150" />
              </Card>
            </div>
            <CardMain>
              <div>
                <Title size="small" color="secondary">
                  Lorem ipsum dolor sit, amet consectetur adipisicing
                  elit. Rerum, voluptatibus
                </Title>
                <Text>
                  Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit, sed do eiusmod tempor incididunt ut labore et
                  dolore
                </Text>
                <Link href="https://google.com.mx" target="blank">
                  Leer más
                </Link>
              </div>
              <Img src="https://via.placeholder.com/150x150" />
            </CardMain>
          </Box>
        )}
      </Container>
    </Main>
  )
}

const mapDispatchToProps = {
  getAll,
}

const mapStateToProps = state => {
  return {
    news: state.news.news,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Vision)

const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  z-index: 1;
  position: relative;
  background-color: #fff;
  padding: 50px 0;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`

const Container = styled.div`
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  text-align: center;
  @media (max-width: 768px) {
  }
`

const Top = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.primary};
  display: flex;
  align-items: center;
  background-color: rgba(66, 186, 237, 0.3);
  display: inline-block;
  padding: 5px;
  justify-content: center;
  line-height: 1;
  margin-bottom: 15px;
  & svg {
    margin-right: 5px;
    font-size: 15px;
  }
`

const Box = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 30px;
  gap: 0 30px;
  align-items: flex-start;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
    padding: 0 20px;
  }
`

const Text = styled.p`
  font-size: 13px;
  line-height: 1.5;
  margin-bottom: 10px;
  color: #42baed;
`

const Subtitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary};
`

const Card = styled.div`
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 1rem;
  text-align: left;
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    & > img {
      margin-bottom: 20px;
    }
  }
`

const Img = styled.img`
  max-height: 300px;
  width: 50%;
  object-fit: cover;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const Link = styled.a`
  color: #d85c38;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  text-decoration: none;
`

const CardMain = styled.div`
  padding: 1rem;
  text-align: left;
  display: flex;
  flex-direction: column-reverse;
  & > img {
    margin-bottom: 20px;
    width: 100%;
  }
`
