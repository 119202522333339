import React from 'react'
import { connect } from 'react-redux'
import { getAll } from '../../actions'
import styled from 'styled-components'
import Title from '../../components/Title'
import { CgEditBlackPoint } from 'react-icons/cg'
import img from '../../assets/static/services.png'

const Vision = props => {
  return (
    <Main id="services">
      <Container>
        <div>
          <Top>
            <CgEditBlackPoint />
            Nosotros
          </Top>
        </div>
        <Title size="mid" color="white">
          Siempre de la mano de nuestros especialistas
        </Title>
        <Box>
          <Info>
            <div>
              <Title color="white">Trámite de pasaporte</Title>
              <Text>
                Lorem ipsum dolor sit amet, consectetur adipiscing
                elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis
                nostrud exercitation ullamco laboris nisi.
              </Text>
              <Title color="white">Trámite de VISA</Title>
              <Text>
                Lorem ipsum dolor sit amet, consectetur adipiscing
                elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis
                nostrud exercitation ullamco laboris nisi.
              </Text>
              <Title color="white">Asesoría Jurídica</Title>
              <Text>
                Lorem ipsum dolor sit amet, consectetur adipiscing
                elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis
                nostrud exercitation ullamco laboris nisi.
              </Text>
            </div>
          </Info>
          <Img src={img} alt="" />
        </Box>
      </Container>
    </Main>
  )
}

const mapDispatchToProps = {
  getAll,
}

const mapStateToProps = state => {
  return {
    banners: state.banners.banners,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Vision)

const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  z-index: 1;
  position: relative;
  background-color: rgba(66, 186, 237, 1);
  padding: 50px 0;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Container = styled.div`
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  text-align: center;
  @media (max-width: 768px) {
  }
`

const Top = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
  display: inline-block;
  padding: 5px;
  justify-content: center;
  line-height: 1;
  margin-bottom: 15px;
  & svg {
    margin-right: 5px;
    font-size: 15px;
  }
`

const Box = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 30px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`

const Img = styled.img`
  width: 500px;
  height: 100%;
  object-fit: contain;
  margin-left: auto;
  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`

const Info = styled.div`
  text-align: left;
`

const Text = styled.p`
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 30px;
`