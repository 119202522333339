import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getAll } from '../../actions'
import styled from 'styled-components'
import Title from '../../components/Title'
import Slider from '../../containers/Slider'
import { CgEditBlackPoint } from 'react-icons/cg'

const Vision = props => {
  const { offices = [] } = props

  useEffect(() => {
    props.getAll('offices', 'GET_OFFICES')
    //eslint-disable-next-line
  }, [])

  if (!offices) {
    return <h1>Cargando...</h1>
  }
  return (
    <Main>
      <Container>
        <div>
          <Top>
            <CgEditBlackPoint />
            Nosotros
          </Top>
        </div>
        <Title size="mid" color="white">
          Estamos al alcance de todos
        </Title>
        <Subtitle>
          Ubica la oficina más cercana, llámanos o acércate y conoce
          todos los servicios que tenemos para ti.
        </Subtitle>
        <SliderContainer>
          <Slider data={offices} name="offices" />
        </SliderContainer>
      </Container>
    </Main>
  )
}

const mapDispatchToProps = {
  getAll,
}

const mapStateToProps = state => {
  return {
    offices: state.offices.offices,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Vision)

const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  z-index: 1;
  position: relative;
  background-color: #66B7EA;
  padding-bottom: 30px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Container = styled.div`
  grid-column: 2/3;
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  @media (max-width: 768px) {
    grid-column: 1;
  }
`

const SliderContainer = styled.div`
  grid-column: 1/4;
  width: 100vw;
  margin-top: 50px;
`

const Top = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
  display: inline-block;
  padding: 5px;
  justify-content: center;
  line-height: 1;
  margin-bottom: 15px;
  & svg {
    margin-right: 5px;
    font-size: 15px;
  }
`

const Subtitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.white};
`
