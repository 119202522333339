import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  setTitle,
  getAll,
  create,
  update,
  deleted,
} from '../../actions'
import MaterialTable from 'material-table'

const Users = props => {
  const { contact, setTitle, localization, themeApp } = props

  useEffect(() => {
    const topbar = {
      title: 'Contacto',
    }
    setTitle(topbar)
    props.getAll('contact', 'GET_CONTACT')
    // eslint-disable-next-line
  }, [])

  const editable = {
    onRowDelete: oldData =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          props
            .deleted(`contact/${oldData.id}`, 'DELETED')
            .then(() => props.getAll('contact', 'GET_CONTACT'))
            .then(() => resolve())
        }, 1000)
      }),
  }

  return (
    <>
      <MaterialTable
        columns={[
          { title: 'Nombre', field: 'name' },
          { title: 'Apellido', field: 'lastname' },
          { title: 'Telefono', field: 'phone' },
          { title: 'Email', field: 'email' },
          { title: 'Mensaje', field: 'mesaje' },
          { title: 'Creado', field: 'created' },
        ]}
        localization={localization}
        data={contact}
        title="Lista de Contacto"
        editable={editable}
        options={{
          pageSize: 50,
          pageSizeOptions: [50, 100, 150],
          emptyRowsWhenPaging: false,
          filterCellStyle: {
            backgroundColor: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.backgroundButton
                : themeApp.darkTheme.backgroundButton
            }`,
            color: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.colorButtonCircle
                : themeApp.darkTheme.colorButtonCircle
            }`,
          },
          headerStyle: {
            backgroundColor: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.backgroundButton
                : themeApp.darkTheme.backgroundButton
            }`,
            color: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.colorButtonCircle
                : themeApp.darkTheme.colorButtonCircle
            }`,
          },
          rowStyle: {
            backgroundColor: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.backgroundButton
                : themeApp.darkTheme.backgroundButton
            }`,
            color: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.colorButtonCircle
                : themeApp.darkTheme.colorButtonCircle
            }`,
          },
          actionsCellStyle: {
            color: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.colorButtonCircle
                : themeApp.darkTheme.colorButtonCircle
            }`,
          },
          searchFieldStyle: {
            color: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.colorButtonCircle
                : themeApp.darkTheme.colorButtonCircle
            }`,
          },
          detailPanelColumnStyle: {
            backgroundColor: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.backgroundButton
                : themeApp.darkTheme.backgroundButton
            }`,
            color: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.colorButtonCircle
                : themeApp.darkTheme.colorButtonCircle
            }`,
          },
        }}
        style={{
          backgroundColor: `${
            themeApp.theme === 'light'
              ? themeApp.lightTheme.backgroundButton
              : themeApp.darkTheme.backgroundButton
          }`,
          color: `${
            themeApp.theme === 'light'
              ? themeApp.lightTheme.colorButtonCircle
              : themeApp.darkTheme.colorButtonCircle
          }`,
        }}
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    contact: state.contact.contact,
    themeApp: state.reducerApp.themeApp,
    localization: state.reducerApp.localization,
  }
}

const mapDispatchToProps = {
  setTitle,
  getAll,
  create,
  update,
  deleted,
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)
