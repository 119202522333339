import React from 'react'
import styled from 'styled-components'
import Title from '../../components/Title'
import img1 from '../../assets/static/about2.png'
import { CgEditBlackPoint } from 'react-icons/cg'

const Vision = () => {
  return (
    <Main id="about">
      <Container>
        <AboutImg src={img1} alt="image" />
        <div>
          <Top>
            <CgEditBlackPoint />
            Nosotros
          </Top>
          <Title size="mid" color={'secondary'}>
            Solucionamos cualquier asunto migratorio
          </Title>
          <Subtitle>
            Contamos con más de 5 oficinas en el Estado de Puebla
          </Subtitle>
          <Text>
            <ul>
              <li>
                Somos un grupo de profesionistas, investigadores y
                expertos que atendemos el fenómeno migratorio entre
                México y los Estados Unidos de América.
              </li>
              <li>
                Contamos con una red de abogados Norteamericanos y
                Mexicanos especialistas en temas de migración, derecho
                civil y constitucional en ambos lados de la frontera.
              </li>
              <li>
                Contamos con una asociación civil que funge como
                puente de comunicación con los migrantes en EE.UU.
              </li>
              <li>
                Somos una marca registrada para pago de remesas,
                servicios legales, paquetería y exportación de
                productos.
              </li>
            </ul>
          </Text>
        </div>
      </Container>
    </Main>
  )
}

export default Vision

const Main = styled.div`
  background-color: ${({ theme }) => theme.white};
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  z-index: 1;
  position: relative;
  color: #000;
  padding: 50px 0;
`

const Container = styled.div`
  grid-column: 2/3;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  @media (max-width: 768px) {
    padding: 30px 15px;
    grid-template-columns: 1fr;
  }
`

const Text = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 2;
  color: #c4c4c4;
  margin: 30px 0;
`

const Top = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.primary};
  display: flex;
  align-items: center;
  background-color: rgba(66, 186, 237, 0.3);
  display: inline-block;
  padding: 5px;
  justify-content: center;
  line-height: 1;
  margin-bottom: 15px;
  & svg {
    margin-right: 5px;
    font-size: 15px;
  }
`

const AboutImg = styled.img`
  width: 100%;
`

const Subtitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary};
`
