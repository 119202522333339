import { combineReducers } from 'redux'
import reducerApp from './reducer'
import users from '../pages/Users/reducer'
import banners from '../pages/Banners/reducer'
import offices from '../pages/Offices/reducer'
import news from '../pages/News/reducer'
import contact from '../pages/Contact/reducer'

const reducer = combineReducers({
  reducerApp,
  users,
  banners,
  offices,
  news,
  contact,
})

export default reducer
