import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Layout from '../layout'
import Login from '../pages/Login'
import Home from '../pages/Home'
import Users from '../pages/Users'
import UsersCreate from '../pages/Users/Create'
import UsersUpdate from '../pages/Users/Update'
import Dashboard from '../pages/Dashboard'

import Banners from '../pages/Banners'
import BannerCreate from '../pages/Banners/create'
import BannerUpdate from '../pages/Banners/update'

import Offices from '../pages/Offices'
import OfficeCreate from '../pages/Offices/create'
import OfficeUpdate from '../pages/Offices/update'

import News from '../pages/News'
import NewCreate from '../pages/News/create'
import NewUpdate from '../pages/News/update'


import Contact from '../pages/Contact'


const App = ({ loggedIn }) => {
  if (loggedIn) {
    return (
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/dashboard/banners" element={<Banners />} />
            <Route
              path="/dashboard/banners/create"
              element={<BannerCreate />}
            />
            <Route
              path="/dashboard/banners/:id"
              element={<BannerUpdate />}
            />
            <Route path="/dashboard/offices" element={<Offices />} />
            <Route
              path="/dashboard/offices/create"
              element={<OfficeCreate />}
            />
            <Route
              path="/dashboard/offices/:id"
              element={<OfficeUpdate />}
            />
            <Route path="/dashboard/news" element={<News />} />
            <Route
              path="/dashboard/news/create"
              element={<NewCreate />}
            />
            <Route
              path="/dashboard/news/:id"
              element={<NewUpdate />}
            />
            <Route path="/dashboard/contact" element={<Contact />} />
        
            <Route path="/dashboard/users" element={<Users />} />
            <Route
              path="/dashboard/users/create"
              element={<UsersCreate />}
            />
            <Route
              path="/dashboard/users/:id"
              element={<UsersUpdate />}
            />
          </Routes>
        </Layout>
      </BrowserRouter>
    )
  } else {
    return (
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    )
  }
}

export default App
