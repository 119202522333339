import React from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'
// Import Swiper styles
import 'swiper/css'

import styled from 'styled-components'

const Slider = props => {
  const { data, name } = props
  return (
    <>
      <Swiper
        spaceBetween={70}
        slidesPerView={'auto'}
        centeredSlides={true}
        slidesPerColumn={1}
        loop={true}
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
        {data && data.length > 0 ? (
          data.map((item, index) => (
            <SlideStyled key={index}>
              <Item>
                <Img
                  src={`${process.env.REACT_APP_API}public/${name}/${item.image}`}
                  alt={'imagen'}
                />
                <Info href={`tel: ${item.tel}`}>{item.tel}</Info>
                <Info>{item.address}</Info>
                <Info href={`mailto: ${item.email}`}>
                  Correo: {item.email}
                </Info>
              </Item>
            </SlideStyled>
          ))
        ) : (
          <>
            <SlideStyled>
              <Item>
                <Img src="https://via.placeholder.com/150x150" />
                <Info href="tel:+522222222222">
                  +52 (55) 1234 5678
                </Info>
                <Info>Blvd. Xxxxx Xxxxxx 23 int. 3423 CP. 34455</Info>
                <Info href="mailto: puebla@casadelmigrante.com">
                  correo: puebla@casadelmigrante.com
                </Info>
              </Item>
            </SlideStyled>
            <SlideStyled>
              <Item>
                <Img src="https://via.placeholder.com/150x150" />
                <Info href="tel:+522222222222">
                  +52 (55) 1234 5678
                </Info>
                <Info>Blvd. Xxxxx Xxxxxx 23 int. 3423 CP. 34455</Info>
                <Info href="mailto: puebla@casadelmigrante.com">
                  correo: puebla@casadelmigrante.com
                </Info>
              </Item>
            </SlideStyled>
            <SlideStyled>
              <Item>
                <Img src="https://via.placeholder.com/150x150" />
                <Info href="tel:+522222222222">
                  +52 (55) 1234 5678
                </Info>
                <Info>Blvd. Xxxxx Xxxxxx 23 int. 3423 CP. 34455</Info>
                <Info href="mailto: puebla@casadelmigrante.com">
                  correo: puebla@casadelmigrante.com
                </Info>
              </Item>
            </SlideStyled>
            <SlideStyled>
              <Item>
                <Img src="https://via.placeholder.com/150x150" />
                <Info href="tel:+522222222222">
                  +52 (55) 1234 5678
                </Info>
                <Info>Blvd. Xxxxx Xxxxxx 23 int. 3423 CP. 34455</Info>
                <Info href="mailto: puebla@casadelmigrante.com">
                  correo: puebla@casadelmigrante.com
                </Info>
              </Item>
            </SlideStyled>
          </>
        )}
      </Swiper>
    </>
  )
}

export default Slider

const Img = styled.img`
  height: 350px;
  width: 300px;
  object-fit: cover;
  margin-bottom: 15px;
`

const Item = styled.div``

const Info = styled.div`
  text-align: left;
  margin-bottom: 10px;
`

const SlideStyled = styled(SwiperSlide)`
  width: auto;
`
