import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  setTitle,
  getAll,
  create,
  update,
  deleted,
} from '../../actions'
import MaterialTable from 'material-table'
import { Link } from 'react-router-dom'
import Button from '../../components/Button'

import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai'
import Swal from 'sweetalert2'
import styled from 'styled-components'

const Users = props => {
  const { banners, setTitle, localization, themeApp } = props

  useEffect(() => {
    const topbar = {
      title: 'Banners',
        buttonBar: (
        <Link to="/dashboard/banners/create">
          <Button>Crear</Button>
        </Link>
      ),
    }
    setTitle(topbar)
    props.getAll('banners', 'GET_BANNERS')
    // eslint-disable-next-line
  }, [])

  const handleDelete = userId => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Este proceso no se puede revertir',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, borrar',
    }).then(result => {
      if (result.isConfirmed) {
        props.deleted(`banners/${userId}`, 'DELETE_USER').then(() => {
          props.getAll('banners', 'GET_USERS')
        })
        Swal.fire('Borrado!', 'Borrado con exito.', 'success')
      }
    })
  }

  return (
    <>
      <MaterialTable
        columns={[
          { title: 'Titulo', field: 'title' },
          { title: 'Texto', field: 'text' },
          {
            title: 'Imagen',
            field: 'image',
            render: rowData => {
              return (
                <Img
                  src={`${process.env.REACT_APP_API}public/banners/${rowData.image}`}
                  alt={rowData.tilte}
                />
              )
            },
          },
          {
            title: 'Acciones',
            field: 'id',
            render: rowData => (
              <>
                <Link to={`/dashboard/banners/${rowData.id}`}>
                  <Button color="warning">
                    <AiOutlineEdit />
                  </Button>
                </Link>
                <Button
                  color="danger"
                  onClick={() => handleDelete(rowData.id)}
                >
                  <AiOutlineDelete />
                </Button>
              </>
            ),
          },
        ]}
        localization={localization}
        data={banners}
        title="Lista de Banners"
        // editable={editable}
        options={{
          pageSize: 50,
          pageSizeOptions: [50, 100, 150],
          emptyRowsWhenPaging: false,
          filterCellStyle: {
            backgroundColor: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.backgroundButton
                : themeApp.darkTheme.backgroundButton
            }`,
            color: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.colorButtonCircle
                : themeApp.darkTheme.colorButtonCircle
            }`,
          },
          headerStyle: {
            backgroundColor: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.backgroundButton
                : themeApp.darkTheme.backgroundButton
            }`,
            color: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.colorButtonCircle
                : themeApp.darkTheme.colorButtonCircle
            }`,
          },
          rowStyle: {
            backgroundColor: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.backgroundButton
                : themeApp.darkTheme.backgroundButton
            }`,
            color: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.colorButtonCircle
                : themeApp.darkTheme.colorButtonCircle
            }`,
          },
          actionsCellStyle: {
            color: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.colorButtonCircle
                : themeApp.darkTheme.colorButtonCircle
            }`,
          },
          searchFieldStyle: {
            color: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.colorButtonCircle
                : themeApp.darkTheme.colorButtonCircle
            }`,
          },
          detailPanelColumnStyle: {
            backgroundColor: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.backgroundButton
                : themeApp.darkTheme.backgroundButton
            }`,
            color: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.colorButtonCircle
                : themeApp.darkTheme.colorButtonCircle
            }`,
          },
        }}
        style={{
          backgroundColor: `${
            themeApp.theme === 'light'
              ? themeApp.lightTheme.backgroundButton
              : themeApp.darkTheme.backgroundButton
          }`,
          color: `${
            themeApp.theme === 'light'
              ? themeApp.lightTheme.colorButtonCircle
              : themeApp.darkTheme.colorButtonCircle
          }`,
        }}
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    banners: state.banners.banners,
    themeApp: state.reducerApp.themeApp,
    localization: state.reducerApp.localization,
  }
}

const mapDispatchToProps = {
  setTitle,
  getAll,
  create,
  update,
  deleted,
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)

const Img = styled.img`
  width: 150px;
`
