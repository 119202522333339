import React from 'react'
import { connect } from 'react-redux'
import { getAll } from '../../actions'
import styled from 'styled-components'
import Title from '../../components/Title'
import { CgEditBlackPoint } from 'react-icons/cg'
import box from '../../assets/static/programs.png'
import caja from '../../assets/static/caja.svg'
import avion from '../../assets/static/avion.svg'
import pago from '../../assets/static/pago.svg'
import family from '../../assets/static/family.svg'

const Vision = props => {
  return (
    <Main id="services">
      <Container>
        <div>
          <Top>
            <CgEditBlackPoint />
            Programas
          </Top>
        </div>
        <Title size="mid" color="secondary">
          Tu eres parte de la Red.
        </Title>
        <Subtitle>
          Generamos programas de alto impacto para nuestra comunidad
          de migrantes.
        </Subtitle>
        <Box>
          <Card>
            <Icon src={family} alt="icon" />
            <Title size="small" color="secondary">
              Reunificación Familiar
            </Title>
            <Text>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt ut labore et dolore
              magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi.
            </Text>
          </Card>
          <Card>
            <Icon src={pago} alt="icon" />
            <Title size="small" color="secondary">
              Envío de Remesas
            </Title>
            <Text>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt ut labore et dolore
              magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi.
            </Text>
          </Card>
          <Card>
            <Icon src={avion} alt="icon" />
            <Title size="small" color="secondary">
              Exportación
            </Title>
            <Text>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt ut labore et dolore
              magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi.
            </Text>
          </Card>
          <Card>
            <Icon src={caja} alt="icon" />
            <Title size="small" color="secondary">
              Paquetería
            </Title>
            <Text>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt ut labore et dolore
              magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi.
            </Text>
          </Card>
        </Box>
      </Container>
    </Main>
  )
}

const mapDispatchToProps = {
  getAll,
}

const mapStateToProps = state => {
  return {
    banners: state.banners.banners,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Vision)

const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  z-index: 1;
  position: relative;
  background-color: #fff;
  padding: 50px 0;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Container = styled.div`
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  text-align: center;
  @media (max-width: 768px) {
  }
`

const Top = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.primary};
  display: flex;
  align-items: center;
  background-color: rgba(66, 186, 237, 0.3);
  display: inline-block;
  padding: 5px;
  justify-content: center;
  line-height: 1;
  margin-bottom: 15px;
  & svg {
    margin-right: 5px;
    font-size: 15px;
  }
`

const Box = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 30px;
  gap: 30px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`

const Text = styled.p`
  font-size: 13px;
  line-height: 1.5;
  margin-bottom: 30px;
  color: #42baed;
`

const Subtitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary};
`

const Card = styled.div`
  background-image: url(${box});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 1rem;
  text-align: left;
`

const Icon = styled.img`
  border-bottom: 1px solid #888888;
  padding-bottom: 10px;
  margin-bottom: 15px;
`
