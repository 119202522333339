import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getAll } from '../../actions'
import styled from 'styled-components'
import Title from '../../components/Title'
import Button from '../../components/ButtonWeb'
import { HashLink } from 'react-router-hash-link'
import bg from '../../assets/static/bg_web.png'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'
import 'swiper/css'

const Banner = props => {
  const { banners } = props

  useEffect(() => {
    props.getAll('banners', 'GET_BANNERS')
    //eslint-disable-next-line
  }, [])

  if (!banners) {
    return <h1>Cargando...</h1>
  }

  return (
    <Main>
      <Container>
        <SwiperStyled
          spaceBetween={50}
          slidesPerView={1}
          centeredSlides={true}
          slidesPerColumn={1}
          loop={true}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
        >
          {banners.map((item, index) => (
            <SlideStyled key={index}>
              <Box left>
                <Top>
                  Trabajamos desde 2015 por ti, para ti y tu familia
                </Top>
                <Title size={'big'} color={'white'}>
                  {item.title}
                </Title>
                <Subititle>{item.text}</Subititle>
                <HashLink to="/#contact" smooth>
                  <Button color={'white'}>INICIA TU TRÁMITE</Button>
                </HashLink>
              </Box>
              <Box>
                <Bg
                  src={`${process.env.REACT_APP_API}public/banners/${item.image}`}
                />
              </Box>
            </SlideStyled>
          ))}
        </SwiperStyled>
      </Container>
    </Main>
  )
}

const mapDispatchToProps = {
  getAll,
}

const mapStateToProps = state => {
  return {
    banners: state.banners.banners,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Banner)

const Main = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  background-image: url(${bg});
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 70% auto;
  background-color: #fff;
  position: relative;
  color: #fff;
  @media (max-width: 768px) {
  }
`

const Container = styled.div`
  grid-column: 2/3;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: 768px) {
    padding: 30px 20px;
    background-color: ${props => (props.left ? '#44648A' : '')};
    padding-top: ${props => (props.left ? '100px' : '')};
  }
`

const Bg = styled.img`
  width: 100%;
  object-fit: contain;
`

const Subititle = styled.p`
  max-width: 500px;
  margin-bottom: 30px;
  line-height: 2;
`

const Top = styled.p`
  background-color: rgba(255, 255, 255, 0.3);
  padding: 5px;
  font-size: 14px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`

const SlideStyled = styled(SwiperSlide)`
  display: grid;
  grid-template-columns: 1fr 40%;
  z-index: 1;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const SwiperStyled = styled(Swiper)`
  width: 100%;
`
