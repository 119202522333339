import React from 'react'
import styled from 'styled-components'
import profile from '../../assets/static/profile.png'
import img1 from '../../assets/static/about.png'

const Contact = props => {
  return (
    <Main id="contact">
      <Container>
        <Img2 src={img1} alt="" srcset="" />
        <InfoContainer>
          <TextContainer>
            <Img src={profile} alt="" srcset="" />
            <div>
              <Subtitle>Mtro. Jesus Torreblanca</Subtitle>
            </div>
            <Subtitle2>Director General Casa del Migrante</Subtitle2>
            <Text>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt ut labore et dolore
              magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi.
            </Text>
          </TextContainer>
        </InfoContainer>
      </Container>
    </Main>
  )
}

export default Contact

const Main = styled.div`
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  position: relative;
  background-color: ${({ theme }) => theme.white};
  padding: 50px 0;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Container = styled.div`
  grid-column: 2/3;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  @media (max-width: 768px) {
    grid-column: 1/2;
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 20px;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 2rem;
  max-width: 600px;
  @media (max-width: 768px) {
    margin-top: 2rem;
    justify-content: center;
    align-items: center;
  }
`

const Subtitle = styled.p`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.secondary};
  border-bottom: 1px solid rgba(217, 217, 217, 1);
  margin: 0;
  padding-bottom: 10px;
  display: inline-block;
`

const Subtitle2 = styled.p`
  font-size: 1rem;
  color: rgba(66, 186, 237, 1);
`

const Text = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.primary};
  margin: 0;
  font-weight: 100;
  @media (max-width: 768px) {
    text-align: center;
  }
`

const Img = styled.img`
  height: 250px;
  width: 250px;
  border-radius: 50%;
  margin: 0 auto 50px auto;
  
`

const Img2 = styled.img`
  width: 100%;
`
