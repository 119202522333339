import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { updateFile, get, setTitle, getAll } from '../../actions'
import Card from '../../components/Card'
import Input from '../../components/Input'
import Button from '../../components/Button'
import styled from 'styled-components'

const CreateSupplier = props => {
  const [form, setForm] = useState({})
  const { setTitle, office } = props
  const { id } = useParams()
  const navigate = useNavigate()
  useEffect(() => {
    const topbar = {
      title: 'Editar Officina',
    }
    setTitle(topbar)
    props.get(`offices/${id}`, 'GET_OFFICE')

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (office) {
      setForm({
        phone: office.phone,
        address: office.address,
        email: office.email,
        imageUrl: office.image,
      })
    }
    // eslint-disable-next-line
  }, [office])

  const handleForm = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const handleFile = e => {
    setForm({
      ...form,
      image: e.target.files,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    props.updateFile(`offices/${id}`, 'POST_USER', form).then(() => {
      navigate('/dashboard/offices')
    })
  }
  if (office) {
    return (
      <Card title={'Información del usuario'}>
        <form id="formUser" onSubmit={e => handleSubmit(e)}>
          <Input
            type="text"
            name="phone"
            title="Telefono"
            onChange={e => handleForm(e)}
            defaultValue={form.phone}
          />

          <Input
            type="text"
            name="address"
            title="Dirección"
            onChange={e => handleForm(e)}
            defaultValue={form.address}
          />
            <Input
            type="text"
            name="email"
            title="Email"
            onChange={e => handleForm(e)}
            defaultValue={form.email}
          />
          <Img
            src={`${process.env.REACT_APP_API}public/offices/${form.imageUrl}`}
            alt={form.address}
          />
          <Input
            type="file"
            name="image"
            title="Imagen"
            onChange={e => handleFile(e)}
            required
          />

          <BarButton>
            <Button type="submit" color="success">
              Editar
            </Button>
            <Link to="/dashboard/offices">
              <Button className="btn --danger">Cancelar</Button>
            </Link>
          </BarButton>
        </form>
      </Card>
    )
  } else {
    return <h1>Cargando</h1>
  }
}

const mapStateToProps = state => {
  return {
    divisions: state.offices.divisions,
    roles: state.offices.roles,
    departments: state.offices.departments,
    office: state.offices.office,
    themeApp: state.reducerApp.themeApp,
    localization: state.reducerApp.localization,
  }
}

const mapDispatchToProps = {
  updateFile,
  get,
  setTitle,
  getAll,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSupplier)

const BarButton = styled.div`
  margin-top: 1rem;
`

const Img = styled.img`
  width: 150px;
`
